
    import Vue from 'vue'
    import {_startcase} from '@/config/util'
    import Archive from '@/assets/svg/ico-archive.svg'
    import ArrowDf from '@/assets/svg/ico-arrow-df.svg'
    import ArrowDown from '@/assets/svg/ico-arrow-down.svg'
    import ArrowLeftAlt from '@/assets/svg/ico-arrow-left-alt.svg'
    import ArrowLeft from '@/assets/svg/ico-arrow-left.svg'
    import ArrowLl from '@/assets/svg/ico-arrow-ll.svg'
    import ArrowRight from '@/assets/svg/ico-arrow-right.svg'
    import ArrowRotate from '@/assets/svg/ico-arrow-rotate.svg'
    import ArrowUp from '@/assets/svg/ico-arrow-up.svg'
    import Arrow from '@/assets/svg/ico-arrow.svg'
    import BanSolid from '@/assets/svg/ico-ban-solid.svg'
    import Badge from '@/assets/svg/ico-badge.svg'
    import Bell from '@/assets/svg/ico-bell.svg'
    import Calendar from '@/assets/svg/ico-calendar.svg'
    import Camera from '@/assets/svg/ico-camera.svg'
    import CheckCircle from '@/assets/svg/ico-check-circle.svg'
    import Check from '@/assets/svg/ico-check.svg'
    import ChevronDown from '@/assets/svg/ico-chevron-down.svg'
    import CloseCircle from '@/assets/svg/ico-close-circle.svg'
    import CloseThin from '@/assets/svg/ico-close-thin.svg'
    import Close from '@/assets/svg/ico-close.svg'
    import Cog from '@/assets/svg/ico-cog.svg'
    import CommCl from '@/assets/svg/ico-comm-cl.svg'
    import Dashboard from '@/assets/svg/ico-dashboard.svg'
    import Desktop from '@/assets/svg/ico-desktop.svg'
    import DotsThree from '@/assets/svg/ico-dots-three.svg'
    import Dots from '@/assets/svg/ico-dots.svg'
    import DotsVertical from '@/assets/svg/ico-dots-vertical.svg'
    import Download from '@/assets/svg/ico-download.svg'
    import Edit from '@/assets/svg/ico-edit.svg'
    import EnvelopeSolid from '@/assets/svg/ico-envelope-solid.svg'
    import Envelope from '@/assets/svg/ico-envelope.svg'
    import Eraser from '@/assets/svg/ico-eraser.svg'
    import Exclamation from '@/assets/svg/ico-exclamation.svg'
    import ExclamationAi from '@/assets/svg/ico-exclamation-ai.svg'
    import External from '@/assets/svg/ico-external.svg'
    import Eye from '@/assets/svg/ico-eye.svg'
    import EyeOff from '@/assets/svg/ico-eye-off.svg'
    import Eyeslash from '@/assets/svg/ico-eyeslash.svg'
    import File from '@/assets/svg/ico-file.svg'
    import GoogleDrive from '@/assets/svg/ico-google-drive.svg'
    import Hand from '@/assets/svg/ico-hand.svg'
    import Happy from '@/assets/svg/ico-happy.svg'
    import IconPaperPlane from '@/assets/svg/ico-icon-paper-plane.svg'
    import Info from '@/assets/svg/ico-info.svg'
    import LinesRight from '@/assets/svg/ico-lines-right.svg'
    import Lines from '@/assets/svg/ico-lines.svg'
    import List from '@/assets/svg/ico-list.svg'
    import Location from '@/assets/svg/ico-location.svg'
    import Loop from '@/assets/svg/ico-loop.svg'
    import MTable from '@/assets/svg/ico-m-table.svg'
    import Manage from '@/assets/svg/ico-manage.svg'
    import MessageRounded from '@/assets/svg/ico-message-rounded.svg'
    import Message from '@/assets/svg/ico-message.svg'
    import Minus from '@/assets/svg/ico-minus.svg'
    import Note from '@/assets/svg/ico-note.svg'
    import PaperPlane from '@/assets/svg/ico-paper-plane.svg'
    import Pin from '@/assets/svg/ico-pin.svg'
    import Phone from '@/assets/svg/ico-phone.svg'
    import PlaneCl from '@/assets/svg/ico-plane-cl.svg'
    import Play from '@/assets/svg/ico-play.svg'
    import PlusCircle from '@/assets/svg/ico-plus-circle.svg'
    import Plus from '@/assets/svg/ico-plus.svg'
    import Print from '@/assets/svg/ico-print.svg'
    import Question from '@/assets/svg/ico-question.svg'
    import QuestionThin from '@/assets/svg/ico-question-thin.svg'
    import QuoteLeft from '@/assets/svg/ico-quote-left.svg'
    import QuoteRight from '@/assets/svg/ico-quote-right.svg'
    import ResetPass from '@/assets/svg/ico-reset-pass.svg'
    import SaveRegular from '@/assets/svg/ico-save-regular.svg'
    import Search from '@/assets/svg/ico-search.svg'
    import Settings from '@/assets/svg/ico-settings.svg'
    import SignOut from '@/assets/svg/ico-sign-out.svg'
    import Skip from '@/assets/svg/ico-skip.svg'
    import StarEmpty from '@/assets/svg/ico-star-empty.svg'
    import Star from '@/assets/svg/ico-star.svg'
    import StarLarge from '@/assets/svg/ico-star-large.svg'
    import StarFilled from '@/assets/svg/ico-star-filled.svg'
    import StarSuccess from '@/assets/svg/ico-star-success.svg'
    import SlidersH from '@/assets/svg/ico-sliders-h.svg'
    import Swipe from '@/assets/svg/ico-swipe.svg'
    import ThumbDown from '@/assets/svg/ico-thumb-down.svg'
    import Heart from '@/assets/svg/ico-heart.svg'
    import HeartLike from '@/assets/svg/ico-heart-like.svg'
    import HeartFilled from '@/assets/svg/ico-heart-filled.svg'
    import HeartDisabled from '@/assets/svg/ico-heart-disabled.svg'
    import ChevronRight from '@/assets/svg/ico-chevron-right.svg'
    import ThumbFilled from '@/assets/svg/ico-thumb-filled.svg'
    import CircleQuestion from '@/assets/svg/ico-circle-question.svg'
    import CircleList from '@/assets/svg/ico-circle-list.svg'
    import IconTable from '@/assets/svg/ico-table.svg'
    import Trash from '@/assets/svg/ico-trash.svg'
    import UserSolid from '@/assets/svg/ico-user-solid.svg'
    import Warning from '@/assets/svg/ico-warning.svg'
    import VideoCamera from '@/assets/svg/ico-video.svg'
    import Lender from '@/assets/svg/ico-lender.svg'
    import NoMessages from '@/assets/svg/ico-no-messages.svg'
    import SolidCalendar from '@/assets/svg/ico-solid-calendar.svg'
    import CustomizeColumns from '@/assets/svg/ico-customize-columns.svg'
    import SolidInfo from '@/assets/svg/ico-solid-info.svg'
    import SolidWarning from '@/assets/svg/ico-solid-warning.svg'
    import Decline from '@/assets/svg/ico-decline.svg'
    import CircleMessage from '@/assets/svg/ico-circle-message.svg'
    import FillWarning from '@/assets/svg/ico-fill-warning.svg'
    import FillColumns from '@/assets/svg/ico-fill-columns.svg'
    import Accept from '@/assets/svg/ico-accept.svg'
    import NoQuotes from '@/assets/svg/ico-no-quotes.svg'
    import CirclePlus from '@/assets/svg/ico-circle-plus.svg'
    import SortingArrows from '@/assets/svg/ico-sorting-arrows.svg'
    import SortingDown from '@/assets/svg/ico-sorting-down.svg'
    import SortingUp from '@/assets/svg/ico-sorting-up.svg'
    import PaginationPrev from '@/assets/svg/ico-pagination-prev.svg'
    import PaginationNext from '@/assets/svg/ico-pagination-next.svg'
    import PaginationDropdown from '@/assets/svg/ico-pagination-dropdown.svg'
    import NoResults from '@/assets/svg/ico-no-results.svg'
    import WarningY from '@/assets/svg/ico-warning-y.svg'
    import Danger from '@/assets/svg/ico-danger.svg'
    import InfoOutline from '@/assets/svg/ico-info-outline.svg'
    import Feather from '@/assets/svg/ico-feather.svg'
    import Wand from '@/assets/svg/ico-wand.svg'
    import Upload from '@/assets/svg/ico-upload.svg'
    import Document from '@/assets/svg/ico-document.svg'
    import CheckAi from '@/assets/svg/ico-check-ai.svg'
    import UploadAi from '@/assets/svg/ico-upload-ai.svg'
    import AlertAi from '@/assets/svg/ico-alert-ai.svg'
    import MegaPhone from '@/assets/svg/ico-mega-phone.svg'
    import ModalArrow from '@/assets/svg/ico-modal-arrow.svg'
    import ModalClose from '@/assets/svg/ico-modal-close.svg'

    export default Vue.extend({
        components: {
            Archive,
            ArrowDf,
            ArrowDown,
            ArrowLeftAlt,
            ArrowLeft,
            ArrowLl,
            ArrowRight,
            ArrowRotate,
            ArrowUp,
            Arrow,
            BanSolid,
            Badge,
            Bell,
            Calendar,
            Camera,
            CheckCircle,
            Check,
            ChevronDown,
            CloseCircle,
            CloseThin,
            Close,
            Cog,
            CommCl,
            Dashboard,
            Desktop,
            DotsThree,
            DotsVertical,
            Dots,
            Download,
            Edit,
            EnvelopeSolid,
            Envelope,
            Eraser,
            Exclamation,
            ExclamationAi,
            External,
            Eye,
            EyeOff,
            Eyeslash,
            File,
            GoogleDrive,
            Hand,
            Happy,
            IconPaperPlane,
            Info,
            LinesRight,
            Lines,
            List,
            Location,
            Loop,
            MTable,
            Manage,
            MessageRounded,
            Message,
            Minus,
            Note,
            PaperPlane,
            Pin,
            Phone,
            PlaneCl,
            Play,
            PlusCircle,
            Plus,
            Print,
            Question,
            QuestionThin,
            QuoteLeft,
            QuoteRight,
            ResetPass,
            SaveRegular,
            Search,
            Settings,
            SignOut,
            Skip,
            SlidersH,
            Star,
            StarLarge,
            StarFilled,
            StarEmpty,
            StarSuccess,
            Swipe,
            ThumbDown,
            Heart,
            HeartLike,
            ChevronRight,
            HeartFilled,
            HeartDisabled,
            ThumbFilled,
            CircleQuestion,
            CircleList,
            IconTable,
            Trash,
            UserSolid,
            Warning,
            VideoCamera,
            NoMessages,
            Lender,
            SolidCalendar,
            CustomizeColumns,
            SolidInfo,
            SolidWarning,
            Decline,
            CircleMessage,
            FillWarning,
            FillColumns,
            Accept,
            NoQuotes,
            CirclePlus,
            SortingArrows,
            PaginationNext,
            PaginationPrev,
            PaginationDropdown,
            SortingUp,
            SortingDown,
            NoResults,
            WarningY,
            Danger,
            InfoOutline,
            Feather,
            Wand,
            Upload,
            Document,
            CheckAi,
            UploadAi,
            AlertAi,
            MegaPhone,
            ModalArrow,
            ModalClose,
        },
        props: {
            iconName: {
                type: String,
                required: true,
            },
        },
        computed: {
            component() {
                return _startcase(this.iconName.replace('ico-', '')).replace(' ', '')
            },
        },
    })
